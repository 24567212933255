/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

/**
 * 인증 유형 (회원가입, 프로필 업데이트, 비밀번호 재설정 등)
 */
export type SendVerificationRequestDtoType =
  (typeof SendVerificationRequestDtoType)[keyof typeof SendVerificationRequestDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SendVerificationRequestDtoType = {
  SIGNUP: "SIGNUP",
  RESET_PASSWORD: "RESET_PASSWORD",
} as const;
