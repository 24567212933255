/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

export type FileType = (typeof FileType)[keyof typeof FileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileType = {
  image: "image",
  document: "document",
  video: "video",
  etc: "etc",
} as const;
