import {
  ACADEMY_URL,
  FAQ_URL,
  NOTIFICATION_RUL,
  PRIVACY_POLICY_URL,
  REFUND_POLICY_URL,
  SERVICE_GUIDE_URL,
  SERVICE_POLICY_URL,
  ZOOM_GUIDE_URL,
} from "@/constants/url";

export const LINK_TYPE = {
  SELF: "SELF",
  BLANK: "BLANK",
  LABEL: "LABEL",
} as const;
export type LinkType = keyof typeof LINK_TYPE;

export const MENU = {
  CONNECT_USER: {
    name: "가입유저",
    link: {
      url: "/connect-user",
      type: LINK_TYPE.SELF,
    },
  },

  LOGIN: {
    name: "로그인",
    link: {
      url: "/login",
      type: LINK_TYPE.SELF,
    },
  },

  SIGNUP: {
    name: "회원가입",
    link: {
      url: "/signup",
      type: LINK_TYPE.SELF,
    },
  },
  SIGNUP_SUCCESS: {
    name: "회원가입 완료",
    link: {
      url: "/signup/success",
      type: LINK_TYPE.SELF,
    },
  },
  FIND_PASSWORD: {
    name: "비밀번호 찾기",
    link: {
      url: "/find/password",
      type: LINK_TYPE.SELF,
    },
  },

  ACADEMY: {
    name: "ACADEMY",
    link: {
      url: "/academy",
      type: LINK_TYPE.SELF,
    },
  },
  ACADEMY1: {
    name: "아카데미",
    link: {
      url: "/academy",
      type: LINK_TYPE.SELF,
    },
  },
  ACADEMY_MUSIC: {
    name: "음악",
    link: {
      url: "/academy/MUSIC",
      type: LINK_TYPE.SELF,
    },
  },
  ACADEMY_VOICE_ACTING: {
    name: "성우",
    link: {
      url: "/academy/VOICE_ACTING",
      type: LINK_TYPE.SELF,
    },
  },

  LIVE_CLASS: {
    name: "LIVE",
    link: {
      url: "/class/live",
      type: LINK_TYPE.SELF,
    },
  },

  //TODO: 관리자에서 클래스 카테고리로 관리
  LIVE_CLASS_MUSIC: {
    name: "작곡",
    link: {
      url: "/class/live/LIVE_CLASS_MUSIC",
      type: LINK_TYPE.SELF,
    },
  },
  LIVE_CLASS_VOCAL: {
    name: "보컬/기악",
    link: {
      url: "/class/live/VOCAL",
      type: LINK_TYPE.SELF,
    },
  },
  LIVE_CLASS_VOICE_ACTING: {
    name: "성우",
    link: {
      url: "/class/live/VOICE_ACTING",
      type: LINK_TYPE.SELF,
    },
  },

  VOD_CLASS: {
    name: "VOD",
    link: {
      url: "/class/vod",
      type: LINK_TYPE.SELF,
    },
  },
  //TODO: 관리자에서 클래스 카테고리로 관리
  VOD_CLASS_DANCE: {
    name: "댄스",
    link: {
      url: "/class/vod/DANCE",
      type: LINK_TYPE.SELF,
    },
  },
  VOD_CLASS_VOICE_ACTING: {
    name: "성우",
    link: {
      url: "/class/vod/VOICE_ACTING",
      type: LINK_TYPE.SELF,
    },
  },
  VOD_CLASS_MUSIC: {
    name: "뮤직",
    link: {
      url: "/class/vod/MUSIC",
      type: LINK_TYPE.SELF,
    },
  },
  VOD_CLASS_CREATIVE: {
    name: "크리에이티브",
    link: {
      url: "/class/vod/CREATIVE",
      type: LINK_TYPE.SELF,
    },
  },

  MANAGEMENT: {
    name: "MANAGEMENT",
    link: {
      url: "/management",
      type: LINK_TYPE.SELF,
    },
  },

  EVENT: {
    name: "EVENT",
    link: {
      url: "/event",
      type: LINK_TYPE.SELF,
    },
  },

  TEACHER_SUPPORT: {
    name: "강사 지원",
    link: {
      url: `/teacher/support`,
      type: LINK_TYPE.SELF,
    },
  },
  TEACHER_APPLY: {
    name: "강사 지원 등록",
    link: {
      url: `/teacher/apply`,
      type: LINK_TYPE.SELF,
    },
  },

  ACADEMY_SUPPORT: {
    name: "학원 입점",
    link: {
      url: `${ACADEMY_URL}/support`,
      type: LINK_TYPE.BLANK,
    },
  },
  TEACHER_SERVICE: {
    name: "교무실",
    link: {
      url: ACADEMY_URL,
      type: LINK_TYPE.BLANK,
    },
  },
  TEACHERS_ROOM_SERVICE: {
    name: "교무실",
    link: {
      url: ACADEMY_URL,
      type: LINK_TYPE.BLANK,
    },
  },

  MY_PAGE: {
    name: "내 정보 수정",
    link: {
      url: "/my",
      type: LINK_TYPE.SELF,
    },
  },
  MY_CLASS: {
    name: "내 클래스",
    link: {
      url: "/my/class",
      type: LINK_TYPE.SELF,
    },
  },
  MY_CLASS_SCHEDULE: {
    name: "스케줄",
    link: {
      url: "/my/schedule",
      type: LINK_TYPE.SELF,
    },
  },
  MY_ORDERS: {
    name: "구매 내역",
    link: {
      url: "/my/orders",
      type: LINK_TYPE.SELF,
    },
  },
  MY_COUPON: {
    name: "쿠폰",
    link: {
      url: "/my/coupon",
      type: LINK_TYPE.SELF,
    },
  },
  MY_POINT: {
    name: "포인트",
    link: {
      url: "/my/point",
      type: LINK_TYPE.SELF,
    },
  },
  MY_CLASS_QNA: {
    name: "강의 질문",
    link: {
      url: "/my/class/qna",
      type: LINK_TYPE.SELF,
    },
  },
  MY_PROFILE: {
    name: "내 정보 수정",
    link: {
      url: "/my/profile",
      type: LINK_TYPE.SELF,
    },
  },
  LOGOUT: {
    name: "로그아웃",
    link: {
      url: "/",
      type: LINK_TYPE.SELF,
    },
  },

  SERVICE_GUIDE: {
    name: "이용가이드",
    link: {
      url: SERVICE_GUIDE_URL,
      type: LINK_TYPE.BLANK,
    },
  },
  ZOOM_GUIDE: {
    name: "ZOOM사용방법",
    link: {
      url: ZOOM_GUIDE_URL,
      type: LINK_TYPE.BLANK,
    },
  },

  FAQ: {
    name: "자주 묻는 질문",
    link: {
      url: FAQ_URL,
      type: LINK_TYPE.BLANK,
    },
  },
  SERVICE_POLICY: {
    name: "이용약관",
    link: {
      url: SERVICE_POLICY_URL,
      type: LINK_TYPE.BLANK,
    },
  },
  PRIVACY_POLICY: {
    name: "개인정보처리방침",
    link: {
      url: PRIVACY_POLICY_URL,
      type: LINK_TYPE.BLANK,
    },
  },
  REFUND_POLICY: {
    name: "환불규정",
    link: {
      url: REFUND_POLICY_URL,
      type: LINK_TYPE.BLANK,
    },
  },
  NOTIFICATION: {
    name: "공지사항",
    link: {
      url: NOTIFICATION_RUL,
      type: LINK_TYPE.BLANK,
    },
  },
} as const;

export interface Menu {
  name: string;
  link: {
    url: string;
    type: LinkType;
  };

  child?: Menu[];
}

export const MENUS: Menu[] = [
  {
    ...MENU.LIVE_CLASS,
    child: [
      MENU.LIVE_CLASS_MUSIC,
      MENU.LIVE_CLASS_VOCAL,
      MENU.LIVE_CLASS_VOICE_ACTING,
    ],
  },
  {
    ...MENU.VOD_CLASS,
    child: [
      MENU.VOD_CLASS_DANCE,
      MENU.VOD_CLASS_VOICE_ACTING,
      MENU.VOD_CLASS_MUSIC,
      MENU.VOD_CLASS_CREATIVE,
    ],
  },
  {
    ...MENU.MANAGEMENT,
  },
  {
    ...MENU.EVENT,
  },
] as const;

export const FOOTER_MENUS: Menu[] = [
  {
    name: "케아클",
    link: {
      url: "/",
      type: LINK_TYPE.LABEL,
    },
    child: [
      MENU.TEACHER_SUPPORT,
      MENU.ACADEMY_SUPPORT,
      MENU.TEACHERS_ROOM_SERVICE,
    ],
  },
  {
    name: "안내",
    link: {
      url: "/",
      type: LINK_TYPE.LABEL,
    },
    child: [MENU.SERVICE_GUIDE, MENU.ZOOM_GUIDE],
  },
  {
    name: "지원",
    link: {
      url: "/",
      type: LINK_TYPE.LABEL,
    },
    child: [
      MENU.NOTIFICATION,
      MENU.FAQ,
      MENU.SERVICE_POLICY,
      MENU.PRIVACY_POLICY,
      MENU.REFUND_POLICY,
    ],
  },
] as const;

export const MY_MENUS: Menu = {
  ...MENU.MY_PAGE,
  child: [MENU.MY_CLASS, MENU.MY_PROFILE],
} as const;
