/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

export type FileResourceType =
  (typeof FileResourceType)[keyof typeof FileResourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileResourceType = {
  courses: "courses",
  instructors: "instructors",
  academies: "academies",
  users: "users",
  admins: "admins",
} as const;
