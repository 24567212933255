/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

/**
 * (관리자 등에 의한) 강의 승인 상태
 */
export type CourseApprovalStatusEnum =
  (typeof CourseApprovalStatusEnum)[keyof typeof CourseApprovalStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CourseApprovalStatusEnum = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
} as const;
