/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

/**
 * 소셜 로그인 제공자 유형 (예: GOOGLE, FACEBOOK)
 */
export type SignUpRequestDtoSocialType =
  (typeof SignUpRequestDtoSocialType)[keyof typeof SignUpRequestDtoSocialType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SignUpRequestDtoSocialType = {
  GOOGLE: "GOOGLE",
  FACEBOOK: "FACEBOOK",
  NAVER: "NAVER",
  KAKAO: "KAKAO",
  APPLE: "APPLE",
} as const;
