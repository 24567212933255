/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

/**
 * 인증 타입
 */
export type AuthType = (typeof AuthType)[keyof typeof AuthType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthType = {
  EMAIL: "EMAIL",
  SOCIAL: "SOCIAL",
} as const;
