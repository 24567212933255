import { useAccessToken } from "@/hooks/auth/use-access-token";
import { SESSION_MAX_AGE } from "@/types/auth";

import {
  getUserControllerGetProfileQueryKey,
  useUserControllerGetProfile,
} from "@shared/generated/api/fn/kac/user/user";

export const useProfile = () => {
  const { accessToken } = useAccessToken();
  const { data, isLoading: isPending } = useUserControllerGetProfile({
    request: {
      accessToken,
    },
    query: {
      enabled: !!accessToken,
      queryKey: getUserControllerGetProfileQueryKey(),
      gcTime: SESSION_MAX_AGE,
    },
  });

  return {
    data,
    isLoading: !data || isPending,
  };
};
