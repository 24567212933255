import { Session as NextAuthSession } from "next-auth";

import { MENU } from "@/constants/menu";

import type { SignUpRequestDtoSocialType } from "@shared/generated/api/model";

// import { UserDto } from '@/generated/api/model'

export const REFRESH_ACCESS_TOKEN_ERROR = "RefreshAccessTokenError";
/**
 * 7일 , 초단위
 */
export const SESSION_MAX_AGE = 60 * 60 * 24 * 7;
export const LOGIN_PATH = MENU.LOGIN.link.url;

export type RefreshAccessTokenError = typeof REFRESH_ACCESS_TOKEN_ERROR;

/**
 * 로그인 후 서버/클라이언트에서 사용할 수 있는 세션 정보
 */
export interface Session extends NextAuthSession {
  // user: UserDto
  accessToken: string;
  /**
   * 액세스 토큰을 리프레시 할 때 에러가 발생한 경우
   * 현재는 이 경우에만 에러 프로퍼티가 추가됨
   */
  error?: RefreshAccessTokenError;
}

export interface ErrorLogin {
  isMember: boolean;
  message: string;
  errors: null;
  status: number;
}

export interface SignUpQuery {
  isSns: boolean;
  email?: string;
  name?: string;
  providerId?: string;
  providerType: string;
}

export interface RegistedUserConnectQuery {
  isSns: boolean;
  email?: string;
  name?: string;
  phone?: string;
  providerType?: SignUpRequestDtoSocialType;
}
