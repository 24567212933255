/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

/**
 * 코스 유형 (예: SINGLE, MULTI, SUBSCRIPTION)
 */
export type CourseTypeEnum =
  (typeof CourseTypeEnum)[keyof typeof CourseTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CourseTypeEnum = {
  SINGLE: "SINGLE",
  MULTI: "MULTI",
  SUBSCRIPTION: "SUBSCRIPTION",
} as const;
