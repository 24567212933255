export const ACADEMY_URL = `${process.env.NEXT_PUBLIC_ACADEMY_HOST}`;

export const SNS_URL = {
  INSTAGRAM: "https://www.instagram.com/k_artist_class/",
  FACEBOOK: "https://www.facebook.com/",
  BLOG: "https://blog.naver.com/k-artistclass",
  YOUTUBE: "https://www.youtube.com/@kartistclass.8696",
  KAKAO: "",
};
//공지사항
export const NOTIFICATION_RUL = "";
//서비스 이용가이드
export const SERVICE_GUIDE_URL =
  "https://scalloped-pail-07e.notion.site/K-ArtistClass-Zoom-2b2d96a706b7470f9d4460925dfbd535";
//줌사용방법
export const ZOOM_GUIDE_URL =
  "https://scalloped-pail-07e.notion.site/K-ArtistClass-Zoom-2b2d96a706b7470f9d4460925dfbd535";

//개인정보수집 약관
export const PRIVACY_POLICY_URL = "https://";
//서비스 이용약관
export const SERVICE_POLICY_URL = "https://";
//자주 묻는 질문
export const FAQ_URL = "https://";
//환불 규정
export const REFUND_POLICY_URL = "https://";

//채널톡 상담
export const CHANNEL_TALK_URL = "http://79pko.channel.io/";
