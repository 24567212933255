/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

export type PurchaseControllerHandleTossFailurePaymentType =
  (typeof PurchaseControllerHandleTossFailurePaymentType)[keyof typeof PurchaseControllerHandleTossFailurePaymentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseControllerHandleTossFailurePaymentType = {
  CARD: "CARD",
  VIRTUAL_ACCOUNT: "VIRTUAL_ACCOUNT",
  MOBILE_PHONE: "MOBILE_PHONE",
  TRANSFER: "TRANSFER",
} as const;
