import dynamic from "next/dynamic";

/**
 * ICON 취급항목은 아래 항목만 취급합니다.
 * 나머지 이미지 svg는 직접 import 하여 사용해주세요.
 * 아이콘 파일명은 피그마에서 다운받는 파일명으로 지정되고
 * 지정안된 경우만 개발자가 별도의 파일명을 지정합니다.
 * icon,
 * graphic
 */
export const ICON_SVG = {
  LINE: {
    SIZE_12: {
      ICON_BILL: "LINE_SIZE_12_ICON_BILL",
      ICON_PLAY: "LINE_SIZE_12_ICON_PLAY",
      ICONS_SETTING: "LINE_SIZE_12_ICON_SETTING",
    },
    SIZE_16: {
      ICON_ARROW_DOWN: "LINE_SIZE_16_ICON_ARROW_DOWN",
      ICON_ARROW_UP: "LINE_SIZE_16_ICON_ARROW_UP",
      ICON_ARROW_RIGHT: "LINE_SIZE_16_ICON_ARROW_RIGHT",
      ICON_LINK: "LINE_SIZE_16_ICON_LINK",
      ICON_CLOSE: "LINE_SIZE_16_ICON_CLOSE",
    },
    SIZE_18: {
      ICON_EYE_OFF: "LINE_SIZE_18_ICON_EYE_OFF",
      ICON_EYE: "LINE_SIZE_18_ICON_EYE",
      ICON_ARROW_RIGHT: "LINE_SIZE_18_ARROW_RIGHT",
      ICON_ARROW_DOWN: "LINE_SIZE_18_ARROW_DOWN",
      ICON_CHECK: "LINE_SIZE_18_CHECK",
      ICON_CLOSE: "LINE_SIZE_18_CLOSE",
    },
    SIZE_24: {
      ICON_SEARCH: "LINE_SIZE_24_ICON_SEARCH",
      ICON_HAMBURGER: "LINE_SIZE_24_ICON_HAMBURGER",
      ICON_CLOSE: "LINE_SIZE_24_ICON_CLOSE",
      ICON_WARNING: "LINE_SIZE_24_ICON_WARNING",
      ICON_CHECKED: "LINE_SIZE_24_ICON_CHECKED",
      ICON_ARROW_RIGHT: "LINE_SIZE_24_ICON_ARROW_RIGHT",
      ICON_ARROW_UP: "LINE_SIZE_24_ICON_ARROW_UP",
      ICON_ARROW_DOWN: "LINE_SIZE_24_ICON_ARROW_DOWN",
    },
  },
  FILL: {},
  GRAPHIC: {
    LOGIN: {
      ICON_APPLE: "GRAPHIC_LOGIN_ICON_APPLE",
      ICON_FACEBOOK: "GRAPHIC_LOGIN_ICON_FACEBOOK",
      ICON_GOOGLE: "GRAPHIC_LOGIN_ICON_GOOGLE",
      ICON_KAKAO: "GRAPHIC_LOGIN_ICON_KAKAO",
      ICON_NAVER: "GRAPHIC_LOGIN_ICON_NAVER",
    },
    AVATAR: {
      ICON_USER: "GRAPHIC_AVATAR_ICON_USER",
    },
    CHECK_BOX: {
      ICON_OUTLINE_DEFAULT: "GRAPHIC_CHECKBOX_ICON_OUTLINE_DEFAULT",
      ICON_OUTLINE_CHECKED: "GRAPHIC_CHECKBOX_ICON_OUTLINE_CHECKED",
      ICON_OUTLINE_ERROR: "GRAPHIC_CHECKBOX_ICON_OUTLINE_ERROR",
    },
    MODAL: {
      ICON_INFO: "GRAPHIC_MODAL_ICON_INFO",
      ICON_CHECK: "GRAPHIC_MODAL_ICON_CHECK",
      ICON_ERROR: "GRAPHIC_MODAL_ICON_ERROR",
      ICON_WARNING: "GRAPHIC_MODAL_ICON_WARNING",
    },
    RADIO: {
      ICON_DEFAULT: "GRAPHIC_CHECKBOX_ICON_DEFAULT",
      ICON_CHECKED: "GRAPHIC_CHECKBOX_ICON_CHECKED",
    },
    PROFILE: {
      ICON_APPLE: "GRAPHIC_PROFILE_ICON_APPLE",
      ICON_FACEBOOK: "GRAPHIC_PROFILE_ICON_FACEBOOK",
      ICON_GOOGLE: "GRAPHIC_PROFILE_ICON_GOOGLE",
      ICON_KAKAO: "GRAPHIC_PROFILE_ICON_KAKAO",
      ICON_NAVER: "GRAPHIC_PROFILE_ICON_NAVER",
    },
  },
} as const;

type TravelWhenObject<T, P = T[keyof T]> = P extends string
  ? P
  : TravelWhenObject<P>;
export type EndOfSvg = TravelWhenObject<typeof ICON_SVG>;

export const ICON_SVG_DYNAMIC_IMPORT = {
  [ICON_SVG.LINE.SIZE_12.ICON_BILL]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/12/line-bill.svg"
    );
    return result.default;
  }),
  [ICON_SVG.LINE.SIZE_12.ICON_PLAY]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/12/line-play.svg"
    );
    return result.default;
  }),
  [ICON_SVG.LINE.SIZE_12.ICONS_SETTING]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/12/line-setting.svg"
    );
    return result.default;
  }),

  [ICON_SVG.LINE.SIZE_16.ICON_ARROW_DOWN]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/16/line-arrow-down.svg"
    );
    return result.default;
  }),
  [ICON_SVG.LINE.SIZE_16.ICON_ARROW_UP]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/16/line-arrow-up.svg"
    );
    return result.default;
  }),
  [ICON_SVG.LINE.SIZE_16.ICON_ARROW_RIGHT]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/16/line-arrow-right.svg"
    );
    return result.default;
  }),

  [ICON_SVG.LINE.SIZE_16.ICON_LINK]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/16/line-link.svg"
    );
    return result.default;
  }),

  [ICON_SVG.LINE.SIZE_16.ICON_CLOSE]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/16/line-close.svg"
    );
    return result.default;
  }),

  [ICON_SVG.LINE.SIZE_18.ICON_EYE]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/18/line-eye.svg"
    );
    return result.default;
  }),

  [ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/18/line-eye-off.svg"
    );
    return result.default;
  }),

  [ICON_SVG.LINE.SIZE_18.ICON_ARROW_RIGHT]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/18/line-arrow-right.svg"
    );
    return result.default;
  }),

  [ICON_SVG.LINE.SIZE_18.ICON_ARROW_DOWN]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/18/line-arrow-down.svg"
    );
    return result.default;
  }),

  [ICON_SVG.LINE.SIZE_18.ICON_CHECK]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/18/line-check.svg"
    );
    return result.default;
  }),

  [ICON_SVG.LINE.SIZE_18.ICON_CLOSE]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/18/line-close.svg"
    );
    return result.default;
  }),

  [ICON_SVG.LINE.SIZE_24.ICON_SEARCH]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/24/line-search.svg"
    );
    return result.default;
  }),
  [ICON_SVG.LINE.SIZE_24.ICON_HAMBURGER]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/24/line-hamburger.svg"
    );
    return result.default;
  }),
  [ICON_SVG.LINE.SIZE_24.ICON_CLOSE]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/24/line-close.svg"
    );
    return result.default;
  }),
  [ICON_SVG.LINE.SIZE_24.ICON_WARNING]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/24/line-warning.svg"
    );
    return result.default;
  }),
  [ICON_SVG.LINE.SIZE_24.ICON_CHECKED]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/24/line-checked.svg"
    );
    return result.default;
  }),
  [ICON_SVG.LINE.SIZE_24.ICON_ARROW_RIGHT]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/24/line-arrow-right.svg"
    );
    return result.default;
  }),

  [ICON_SVG.LINE.SIZE_24.ICON_ARROW_UP]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/24/line-arrow-up.svg"
    );
    return result.default;
  }),
  [ICON_SVG.LINE.SIZE_24.ICON_ARROW_DOWN]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/icons/line/24/line-arrow-down.svg"
    );
    return result.default;
  }),

  [ICON_SVG.GRAPHIC.LOGIN.ICON_FACEBOOK]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/login/facebook-icon.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.LOGIN.ICON_KAKAO]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/login/kakao-icon.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.LOGIN.ICON_NAVER]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/login/naver-icon.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.LOGIN.ICON_APPLE]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/login/apple-icon.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.LOGIN.ICON_GOOGLE]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/login/google-icon.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.AVATAR.ICON_USER]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/avatar/user.svg"
    );
    return result.default;
  }),

  [ICON_SVG.GRAPHIC.CHECK_BOX.ICON_OUTLINE_DEFAULT]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/check-box/outline-default.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.CHECK_BOX.ICON_OUTLINE_CHECKED]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/check-box/outline-checked.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.CHECK_BOX.ICON_OUTLINE_ERROR]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/check-box/outline-error.svg"
    );
    return result.default;
  }),

  [ICON_SVG.GRAPHIC.MODAL.ICON_INFO]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/modal/state=info.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.MODAL.ICON_CHECK]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/modal/state=fill_check.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.MODAL.ICON_ERROR]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/modal/state=fill_erorr.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.MODAL.ICON_WARNING]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/modal/state=fill_warning.svg"
    );
    return result.default;
  }),

  [ICON_SVG.GRAPHIC.RADIO.ICON_DEFAULT]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/radio/default.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.RADIO.ICON_CHECKED]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/radio/checked.svg"
    );
    return result.default;
  }),

  [ICON_SVG.GRAPHIC.PROFILE.ICON_APPLE]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/profile/apple.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.PROFILE.ICON_FACEBOOK]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/profile/facebook.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.PROFILE.ICON_GOOGLE]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/profile/google.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.PROFILE.ICON_KAKAO]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/profile/kakao.svg"
    );
    return result.default;
  }),
  [ICON_SVG.GRAPHIC.PROFILE.ICON_NAVER]: dynamic(async () => {
    const result = await import(
      "@shared/lib/assets/images/graphic/profile/naver.svg"
    );
    return result.default;
  }),
} as const;
