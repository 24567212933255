import { useMemo } from "react";
import Link from "next/link";
import classNames from "classnames";
import styled from "@emotion/styled";

import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { LINK_TYPE, Menu } from "@/constants/menu";

interface HamburgerMenuItemProps {
  main: Menu;
  index: number;
  subMenuActiveIndex: number | null;
  openSubMenu: (index: number) => void;
  closeHamburgerMenu: () => void;
}

export const HamburgerMenuItem = ({
  main,
  index,
  subMenuActiveIndex,
  openSubMenu,
  closeHamburgerMenu,
}: HamburgerMenuItemProps) => {
  const isSubMenuActive = useMemo(
    () => subMenuActiveIndex === index,
    [subMenuActiveIndex, index],
  );

  return (
    <li
      className={classNames("main", {
        ["is-sub"]: !!main.child,
      })}
      key={`main-menu-${index}`}
    >
      {!!main.child && (
        <>
          <a
            className="main"
            title={main.name}
            onClick={() => openSubMenu(index)}
          >
            <span className="main">{main.name}</span>
            <MenuArrowIcon
              icon={
                isSubMenuActive
                  ? ICON_SVG.LINE.SIZE_16.ICON_ARROW_UP
                  : ICON_SVG.LINE.SIZE_16.ICON_ARROW_DOWN
              }
            />
          </a>

          <ul
            className={classNames(
              {
                first: index === 0,
                active: isSubMenuActive,
              },
              "sub",
            )}
          >
            {main.child?.map((sub, subIndex) => (
              <li
                className="sub"
                key={`sub-menu-${subIndex}`}
              >
                <Link
                  href={sub.link.url}
                  target={sub.link.type === LINK_TYPE.SELF ? "_self" : "_blank"}
                  title={sub.name}
                  onClick={closeHamburgerMenu}
                  passHref
                >
                  <span className="sub">{sub.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}

      {!main.child && (
        <Link
          href={main.link.url}
          target={main.link.type === LINK_TYPE.SELF ? "_self" : "_blank"}
          title={main.name}
          passHref
        >
          <span className="main">{main.name}</span>
        </Link>
      )}
    </li>
  );
};

const MenuArrowIcon = styled(Icon)`
  width: 16px;
  height: 16px;

  path {
    stroke: ${({ theme }) => theme.colors.icon.active.black};
  }
`;
