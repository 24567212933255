import styled from "@emotion/styled";

import { MENUS } from "@/constants/menu";
import { HEADER_HEIGHT } from "@/constants/style/layout";
import { GnbMenuItem } from "@/layout/header/gnb-menu/gnb-menu-item";

export const GnbMenu = () => (
  <GnbMenuContainer>
    <ul className="main">
      {MENUS.map((menu, index) => (
        <GnbMenuItem
          key={`menu-row-${index}`}
          main={menu}
          index={index}
        />
      ))}
    </ul>
  </GnbMenuContainer>
);

const GnbMenuContainer = styled.nav`
  display: flex;
  align-items: center;

  width: 100%;

  ul {
    li {
      a {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  ul.main {
    display: flex;
    align-items: center;

    height: 100%;

    li.main {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;

      min-height: 16px;
      height: 100%;
      padding: 8px 16px 0 16px;

      &:first-of-type {
        padding: 8px 16px 0 0;
      }

      a {
        padding-bottom: 8px;
        span.main {
          height: 100%;
          display: flex;
          align-items: center;

          color: ${({ theme }) => theme.colors.text.high.white};
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 14px */
        }
      }

      &.is-sub {
        &:hover {
          z-index: 1;
          position: relative;

          a.main {
            padding-bottom: 7px;
            border-bottom: 1px solid
              ${({ theme }) => theme.colors.brand.white.default};
          }

          ul.sub {
            z-index: -1;
            position: absolute;
            top: 32px;
            left: 0;

            display: flex;
            width: 104px;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;

            border-radius: 0 0 8px 8px;
            background: rgba(0, 0, 0, 0.8);

            &.first {
              left: -16px;
            }
          }
        }

        ul.sub {
          display: none;
          li.sub {
            min-height: 19px;
            span.sub {
              color: ${({ theme }) => theme.colors.text.high.white};
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%; /* 14px */
              letter-spacing: -0.4px;
            }

            &:hover {
              span.sub {
                color: ${({ theme }) => theme.colors.brand.primary.default};
              }
            }
          }
        }
      }
    }
  }

  ${({ theme }) => theme.media.tablet} {
    overflow-x: scroll;
    ul.main {
      li.main {
        &.is-sub {
          &:hover {
            ul.sub {
              width: 100vw;
              position: fixed;
              top: ${HEADER_HEIGHT.TABLET}px;
              left: 0;

              &.first {
                left: 0;
              }
            }
          }
        }
      }
    }
  }
`;
