import { ReactNode } from "react";
import styled from "@emotion/styled";

import { HEADER_HEIGHT } from "@/constants/style/layout";
import { Footer } from "@/layout/footer/footer";
import { Header } from "@/layout/header/header";
import { LayoutConfig } from "@/types/layout-config";

interface RootLayoutProps {
  children: ReactNode;
  layoutConfig?: LayoutConfig;
}
export const RootLayout = ({ children, layoutConfig }: RootLayoutProps) => (
  <>
    <Header layoutConfig={layoutConfig} />
    <Main>{children}</Main>
    <Footer layoutConfig={layoutConfig} />
  </>
);

const Main = styled.main`
  padding-top: ${HEADER_HEIGHT.PC}px;

  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.media.tablet} {
    padding-top: ${HEADER_HEIGHT.TABLET}px;
  }

  ${({ theme }) => theme.media.mobile} {
    padding-top: ${HEADER_HEIGHT.MOBILE}px;
  }
`;
