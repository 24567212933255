/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * K-Artist Class API
 * OpenAPI spec version: 0.0.1
 */

/**
 * 소셜 로그인 타입
 */
export type SocialType = (typeof SocialType)[keyof typeof SocialType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocialType = {
  GOOGLE: "GOOGLE",
  FACEBOOK: "FACEBOOK",
  NAVER: "NAVER",
  KAKAO: "KAKAO",
  APPLE: "APPLE",
} as const;
